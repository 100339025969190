import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import CollapseCard from './CollapseCard';

const FAQ = () => (
    <Container tag="article" className="h-100">
        <Helmet title="FAQ" />
        <Row className="h-100 py-5 align-items-center text-center">
            <Col lg={{ size: 8, offset: 2 }}>
                <h1 className="mb-3">FAQ</h1>
                <div className="accordion" id="faq">
                    <CollapseCard title="FAQ Item Title">
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                    </CollapseCard>
                </div>
            </Col>
        </Row>
    </Container>
);

export default FAQ;

import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

const Root = () => (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

if (rootElement.hasChildNodes()) hydrate(<Root />, rootElement);
else render(<Root />, rootElement);

// Service Worker
// https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
serviceWorker.register();

import React from 'react';
import { string, oneOfType, object, array } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialLink = ({ href, icon }) => (
    <a
        className="mx-2 mx-md-3"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        <FontAwesomeIcon
            icon={icon}
            fixedWidth
            size="2x"
            className="social-link"
        />
    </a>
);

SocialLink.propTypes = {
    href: string.isRequired,
    icon: oneOfType([object, array, string]).isRequired
};

export default SocialLink;

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
    faTwitter,
    // faInstagram,
    faFacebook,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import SocialLink from '../SocialLink';

const socialLinks = [
    {
        href: process.env.REACT_APP_TWITTER_URL,
        icon: faTwitter
    },
    /* {
        href: process.env.REACT_APP_INSTAGRAM_URL,
        icon: faInstagram
    }, */
    {
        href: process.env.REACT_APP_FACEBOOK_URL,
        icon: faFacebook
    },

    {
        href: process.env.REACT_APP_LINKEDIN_URL,
        icon: faLinkedin
    }
];

const Footer = () => (
    <Container tag="footer" className="my-4 fixed-bottom">
        <Row>
            <Col className="d-md-flex mb-3 align-items-center justify-content-center text-center">
                <h5 className="d-block d-md-inline mb-3 mb-md-0 mr-md-3 font-weight-normal">
                    Follow Us
                </h5>
                {socialLinks.map(({ href, icon }) => (
                    <SocialLink key={href} href={href} icon={icon} />
                ))}
            </Col>
        </Row>
        <Row>
            <Col className="text-center text-muted">
                &copy; {new Date().getFullYear()} Pallet
            </Col>
        </Row>
    </Container>
);

export default Footer;

import React from 'react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import MainContainer from '../MainContainer';

const ComingSoon = () => (
    <MainContainer
        title="Coming Soon"
        header="GET READY TO LAUNCH"
        text="Pallet is coming soon! We're working hard to give you the best user experience for something we think you'll love."
        button={{
            text: 'Contact Us',
	        href: process.env.REACT_APP_PALLET_CONTACT_EMAIL,
	        icon: faEnvelope

        }}
    />
);

export default ComingSoon;

import React from 'react';
import { shape, string } from 'prop-types';
import Helmet from 'react-helmet';
import { withRouter, Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Header from '../Header';
import Footer from '../Footer';
import ComingSoon from '../ComingSoon';
import About from '../About';
import FAQ from '../FAQ';
import './App.scss';

const App = ({ location }) => (
    <div className="h-100">
        <Helmet title="Pallet" titleTemplate="Pallet » %s" />
        <Header />
        <TransitionGroup component="section" className="h-100">
            <CSSTransition
                key={location.pathname.split('/')[1] || '/'}
                timeout={{ enter: 300, exit: 200 }}
                classNames="fade"
                appear
            >
                <Switch location={location}>
                    <Route path="/about" component={About} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/" component={ComingSoon} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
        <Footer />
    </div>
);

App.propTypes = {
    location: shape({
        pathname: string.isRequired
    }).isRequired
};

export default withRouter(App);

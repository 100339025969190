import React from 'react';
import { node, string, shape, oneOfType, object, array } from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MainContainer = ({ title, header, text, button }) => (
    <Container tag="article" className="h-100">
        {title && <Helmet title={title} />}
        <Row className="h-100 py-5 align-items-center text-center">
            <Col lg={{ size: 8, offset: 2 }}>
                <h1 className="mb-3">{header}</h1>
                <p className="lead">{text}</p>
                <Button
                    tag={button.href ? 'a' : Link}
                    href={button.href}
                    to={button.to || ''}
                    size="lg"
                    color="light"
                    className="mt-4 text-primary"
                >
                    <FontAwesomeIcon
                        icon={button.icon}
                        fixedWidth
                        className="mr-2"
                    />
                    {button.text}
                </Button>
            </Col>
        </Row>
    </Container>
);

MainContainer.defaultProps = {
    button: {
        href: undefined,
        to: undefined
    }
};

MainContainer.propTypes = {
    title: string.isRequired,
    header: string.isRequired,
    text: string.isRequired,
    button: shape({
        text: string.isRequired,
        href: node,
        to: node,
        icon: oneOfType([object, array, string]).isRequired
    })
};

export default MainContainer;

import React, { useState } from 'react';
import { string, oneOfType, node, arrayOf } from 'prop-types';
import classNames from 'classnames';
import { Collapse, Card, CardHeader, CardBody } from 'reactstrap';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CollapseCard.module.scss';

const CollapseCard = ({ title, children, isOpen: _isOpen }) => {
    const id = `card-collapse-${title}`;
    const [isOpen, setIsOpen] = useState(_isOpen);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <Card className={classNames(styles.root, 'text-dark')}>
            <CardHeader
                className={classNames(
                    styles.header,
                    'd-flex',
                    'align-items-top',
                    'justify-content-between'
                )}
                id={id}
                onClick={toggle}
            >
                <h5 className="mb-0">{title}</h5>
                <div>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={isOpen ? faChevronUp : faChevronDown}
                    />
                </div>
            </CardHeader>
            <Collapse
                isOpen={isOpen}
                aria-labelledby={id}
                data-parent="#faq"
                className="text-left"
            >
                <CardBody>{children}</CardBody>
            </Collapse>
        </Card>
    );
};

CollapseCard.propTypes = {
    title: string.isRequired,
    isOpen: string,
    children: oneOfType([node, arrayOf(node)]).isRequired
};

CollapseCard.defaultProps = {
    isOpen: false
};

export default CollapseCard;

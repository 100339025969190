import React from 'react';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import MainContainer from '../MainContainer';

const About = () => (
    <MainContainer
        title="About Us"
        header="ABOUT PALLET"
        text=" Pallet is a novel marketplace platform where construction developers can post projects, connect with bidders, and receive proposals, keeping everyone organized, ultimately lowering costs and saving time."
        button={{
            text: 'Back',
            to: '/',
            icon: faArrowLeft
        }}
    />
);

export default About;

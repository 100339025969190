import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import Logo from '../Logo';

const Header = () => (
    <Container tag="header" className="fixed-top">
        <Row>
            <Col className="d-flex">
                <div>
                    <Link
                        to="/"
                        className="logo d-block p-2 p-md-4 mr-3 bg-white"
                    >
                        <Logo height="54px" alt="Pallet" />
                    </Link>
                </div>
                <div className="p-1 p-md-3">
                    <h5>connect. build. simple.</h5>
                    <Button
                        tag={NavLink}
                        to="/"
                        exact
                        color="secondary"
                        className="mr-2"
                        activeClassName="d-none"
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            fixedWidth
                            className="mr-2"
                        />
                        Back
                    </Button>
                    <Button
                        tag={NavLink}
                        to="/about"
                        exact
                        color="secondary"
                        className="mr-2"
                        activeClassName="d-none"
                    >
                        About Us
                    </Button>
                    <Button
                        tag={NavLink}
                        to="/faq"
                        exact
                        color="secondary"
                        activeClassName="d-none"
                    >
                        FAQ
                    </Button>
                </div>
            </Col>
        </Row>
    </Container>
);

export default Header;
